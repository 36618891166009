import React, { useRef, useState, useEffect, useCallback } from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { clickOutside } from '../../../utils/common';

import Chime from './icons/Chime.png';
import ChimeActive from './icons/Chime-active.png';
import Tambourine from './icons/Tambourine.png';
import TambourineActive from './icons/Tambourine-active.png';
import Triangle from './icons/Triangle.png';
import TriangleActive from './icons/Triangle-active.png';
import Trumpet from './icons/Trumpet.png';
import TrumpetActive from './icons/Trumpet-active.png';
import Woodshaker from './icons/Woodshaker.png';
import WoodshakerActive from './icons/Woodshaker-active.png';
import Xylophone from './icons/Xylophone.png';
import XylophoneActive from './icons/Xylophone-active.png';

import styles from './InstrumentPlayer.module.scss';

const iconMap = {
  chime: Chime,
  'chime-active': ChimeActive,
  tambourine: Tambourine,
  'tambourine-active': TambourineActive,
  triangle: Triangle,
  'triangle-active': TriangleActive,
  trumpet: Trumpet,
  'trumpet-active': TrumpetActive,
  woodshaker: Woodshaker,
  'woodshaker-active': WoodshakerActive,
  xylophone: Xylophone,
  'xylophone-active': XylophoneActive,
};

const InstrumentPlayer = ({ src, className, name, active = true }) => {
  const [playing, setPlaying] = useState(false);

  const audioRef = useRef(null);
  const btnRef = useRef(null);

  const intrumentEl = iconMap[name];
  const intrumentActiveEl = iconMap[`${name}-active`];

  const [instrumentSrc, setInstrumentSrc] = useState(intrumentEl);

  useEffect(() => {
    if (!audioRef.current) {
      return;
    }

    if (playing) {
      audioRef.current.currentTime = 0;
      audioRef.current.play();
      setInstrumentSrc(intrumentActiveEl);
      return clickOutside(btnRef.current, () => {
        setPlaying(false);
      });
    } else {
      audioRef.current.pause();
      setInstrumentSrc(intrumentEl);
    }
  }, [playing, intrumentEl, intrumentActiveEl]);

  const onBtnClick = useCallback(() => {
    setPlaying(state => !state);

    if (!playing) {
      audioRef.current.play();
    }
  }, [playing]);

  return (
    <>
      <audio ref={audioRef} src={src} onEnded={() => setPlaying(false)}>
        Your browser does not support the audio element.
      </audio>

      <div
        ref={btnRef}
        aria-label="Play text audio"
        onClick={onBtnClick}
        className={classNames(styles.wrapper, { [styles.playing]: playing }, className)}
        tabIndex={active ? 0 : -1}
      >
        <img src={instrumentSrc} alt={name} />
      </div>
    </>
  );
};

InstrumentPlayer.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
};

export { InstrumentPlayer };
