import React from 'react';
import Img from 'gatsby-image';
import { HubAudioPlayer } from '../../../common/HubAudioPlayer';
import { ReactComponent as AudioBox01 } from '../../../../images/svg/audiopairs/001.svg';
import { ReactComponent as AudioBox02 } from '../../../../images/svg/audiopairs/002.svg';
import { ReactComponent as AudioBox03 } from '../../../../images/svg/audiopairs/003.svg';
import { TextSvg } from '../../../common/TextSvg';
import { Title } from '../../../common/Title';
import { InternalExternalLink } from '../../../common/InternalExternalLink';
import { Icon } from '../../../common/Icon/Icon';
import styles from './AudioImagePairs.module.scss';

const svgs = [AudioBox01, AudioBox02, AudioBox03];

const AudioImagePairs = ({ pairs }) => {
  return (
    <>
      <Title className={styles.title} tagName="h2">
        Hear from some people in our team...
      </Title>
      <div className={styles.pairGrid}>
        {pairs.map((pair, index) => (
          <div className={'hub-col'} key={`audio-image-pair-${index}`}>
            <TextSvg innerClassName={styles.audio} svg={svgs[index % svgs.length]}>
              <div className={styles.audioImageWrapper}>
                {pair.image?.localFile?.childImageSharp && (
                  <Img
                    className={styles.audioImage}
                    fluid={pair.image?.localFile?.childImageSharp.fluid}
                    alt={pair.image?.altText}
                  />
                )}
                {!pair.image?.localFile?.childImageSharp && pair.image?.localFile?.publicURL && (
                  <img className={styles.audioImage} src={pair.image?.localFile?.publicURL} alt="" />
                )}
              </div>

              <HubAudioPlayer src={pair.audio?.localFile?.publicURL} label={pair.listenText} />

              {pair.downloadable?.localFile?.publicURL && (
                <InternalExternalLink className={styles.downloadLink} to={pair.downloadable?.localFile?.publicURL}>
                  <Icon size="free" className={styles.downloadLinkIcon} name="download" /> Download the words
                </InternalExternalLink>
              )}
            </TextSvg>
          </div>
        ))}
      </div>
    </>
  );
};

export { AudioImagePairs };
