import React from 'react';
import Img from 'gatsby-image';
import { Icon } from '../../../common/Icon/Icon';
import styles from './GraphicQuotesBlock.module.scss';

const GraphicQuotesBlock = ({ quotes }) => {
  return (
    <>
      <div className={styles.scroll}>
        <div className={styles.graphicQuotesContainer}>
          {quotes.map((quote, index) => (
            <div className={styles.item} key={`graphic-quote-${index}`}>
              {quote.image?.localFile?.extension === 'svg' && (
                <img src={quote.image.localFile.publicURL} alt={quote.image.altText} />
              )}
              {quote.image?.localFile?.childImageSharp && (
                <Img fluid={quote.image?.localFile?.childImageSharp?.fluid} alt={quote.image.altText} />
              )}
            </div>
          ))}
        </div>
      </div>

      <div className={styles.prompt}>
        Scroll for more <Icon className={styles.promptIcon} size="free" name="arrow-drawn" />
      </div>
    </>
  );
};

export { GraphicQuotesBlock };
