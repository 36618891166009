import React, { useMemo } from 'react';
import Img from 'gatsby-image';

import { Wrapper } from '../../../layout/Wrapper';
import { HubAudioPlayer } from '../../../common/HubAudioPlayer';
import { InternalExternalLink } from '../../../common/InternalExternalLink';
import { HandwrittenTitle } from '../../../common/HandwrittenTitle';
import { Icon } from '../../../common/Icon/Icon';

import styles from './PostHeader.module.scss';

const PostHeader = ({ title, audioAlternative, articleDescription, audioDiscussion }) => {
  const audioDiscussionFile = useMemo(() => {
    return audioDiscussion?.audioFile?.localFile?.publicURL || audioDiscussion?.audioFile?.mediaItemUrl;
  }, [audioDiscussion]);

  return (
    <Wrapper innerClassName={styles.container} wrapped border>
      {/* Introduction and Audio Discussion */}
      <div className={styles.left}>
        <HandwrittenTitle className={styles.title} tagEl="h1" title={title} />

        <div
          dangerouslySetInnerHTML={{
            __html: articleDescription.longDescription,
          }}
        />

        {audioAlternative && (
          <div className={styles.leftAudio}>
            <HubAudioPlayer src={audioAlternative} label="Listen" />
          </div>
        )}
      </div>

      <aside className={styles.right}>
        <div>
          {audioDiscussion?.audioDiscussionImage?.localFile?.childImageSharp?.fluid && (
            <Img fluid={audioDiscussion?.audioDiscussionImage?.localFile?.childImageSharp?.fluid} />
          )}
        </div>
        {audioDiscussionFile && (
          <div className={styles.listenTitle}>
            <HubAudioPlayer
              className={styles.listenTitlePlayer}
              src={audioDiscussionFile}
              label={audioDiscussion?.audioDiscussionTitle}
              noIndicator
            />
          </div>
        )}
        {audioDiscussion?.audioDescriptionTranscript?.localFile?.publicURL && (
          <div className={styles.download}>
            <InternalExternalLink
              className={styles.downloadLink}
              to={audioDiscussion?.audioDescriptionTranscript?.localFile?.publicURL}
            >
              <Icon className={styles.downloadLinkIcon} name="download" /> Download the words
            </InternalExternalLink>
          </div>
        )}
      </aside>
    </Wrapper>
  );
};

export { PostHeader };
