import React from 'react';
import { ExtraItem } from './ExtraItem';
import styles from './ExtraItems.module.scss';

const ExtraItems = ({ extras }) => {
  return (
    <div className={styles.extraItemsContainer}>
      {extras.map((extra, index) => (
        <div className={styles.extraItemsItem} key={`extra-item-${index}`}>
          <ExtraItem
            className={styles.item}
            to={extra.itemLink}
            text={extra.itemTitle}
            file={extra.itemFile?.localFile?.publicURL}
            category={extra.itemCategory}
          />
        </div>
      ))}
    </div>
  );
};

export { ExtraItems };
