import React from 'react';
import { InstrumentPlayer } from '../../../common/InstrumentPlayer';
import { Title } from '../../../common/Title';
import { Icon } from '../../../common/Icon/Icon';
import { VideoEmbed } from '../VideoCarousel/VideoEmbed';
import styles from './VideoAudioImagePairs.module.scss';

const VideoAudioImagePairs = ({ videoAIPairs }) => {
  return (
    <div className={styles.vaiContainer}>
      <div className={styles.vaiHeader}>
        <Title className={styles.mainTitle} size="large" tagName="h2" center bold>
          Play Along
        </Title>
        <Title className={styles.subTitle} size="normal" tagName="p" center>
          Click on <Icon flip name="click" size="large" className={styles.subTitleIcon} />
          the instruments to join in
        </Title>
      </div>
      <div className={styles.videoPairsGrid}>
        <div>{videoAIPairs.videoEmbedLink && <VideoEmbed url={videoAIPairs.videoEmbedLink} />}</div>
        <div className={styles.aiPairs}>
          {videoAIPairs?.audioAndImagePairs?.map((pair, index) => (
            <div key={`video-audio-image-pair-${index}`}>
              <InstrumentPlayer src={pair.audio?.localFile?.publicURL} name={pair.name} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export { VideoAudioImagePairs };
