import React from 'react';
import classNames from 'classnames';
import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Wrapper } from '../components/layout/Wrapper';
import { AudioImagePairs } from '../components/pagelayout/article/AudioImagePairs';
import { ArtworkPageCards } from '../components/pagelayout/article/ArtworkPageCards';
import { GraphicQuotesBlock } from '../components/pagelayout/article/GraphicQuotesBlock';
import { VideoCarousel } from '../components/pagelayout/article/VideoCarousel';
import { ExtraItems } from '../components/pagelayout/article/ExtraItems';
import SEO from '../components/common/seo';
import { Breadcrumb } from '../components/common/Breadcrumb';
import { Title } from '../components/common/Title';
import { TagsSectionFooter } from '../components/layout/TagsSectionFooter';
import { PostHeader } from '../components/pagelayout/post/PostHeader';
import { TextSvg } from '../components/common/TextSvg';
import { getCategoryColor } from '../components/context/AppContext';
import { EventItem } from '../components/layout/EventsOverlay/EventItem';
import { getFeaturedImageSrc, getPostCategory } from '../utils/data';
import { VideoAudioImagePairs } from '../components/pagelayout/article/VideoAudioImagePairs';
import ComingSoonSvg from '../images/svg/coming-soon.svg';

import styles from './Post.module.scss';

export const query = graphql`
  query($slug: String!) {
    post: wpPost(slug: { eq: $slug }) {
      content
      title
      slug
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
      categories {
        nodes {
          slug
          name
        }
      }
      articleDescription {
        longDescription
        shortDescription
      }
      audioAlternative {
        longDescriptionAudioAlternative {
          mediaItemUrl
          localFile {
            publicURL
          }
        }
      }
      audioDiscussion {
        audioDiscussionTitle
        audioDiscussionImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 640) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        audioFile {
          mediaItemUrl
          localFile {
            publicURL
          }
        }
        audioDescriptionTranscript {
          mediaItemUrl
          localFile {
            publicURL
          }
        }
      }
      graphicQuotes {
        quoteImages {
          image {
            altText
            localFile {
              publicURL
              extension
              childImageSharp {
                fluid(maxWidth: 1024) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
      videoCarousel {
        videos {
          videoEmbedLink
          alternativeText
          videoStill {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1024) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
      extraContent {
        extraItems {
          itemTitle
          itemLink
          itemCategory
          itemFile {
            mediaItemUrl
            localFile {
              publicURL
            }
          }
        }
      }
      videoAudioImagePairs {
        videoEmbedLink
        audioAndImagePairs {
          name
          audio {
            altText
            localFile {
              publicURL
            }
          }
        }
      }
      audioImage {
        pairs {
          listenText
          audio {
            localFile {
              publicURL
            }
          }
          image {
            altText
            localFile {
              publicURL
              extension
              childImageSharp {
                fluid(maxWidth: 640) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          downloadable {
            localFile {
              publicURL
            }
          }
        }
      }
      artworkPageCards {
        artworkCards {
          artworkTitle
          artworkDescription
          artworkLink {
            url
          }
          artworkImage {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          artworkAudioAlternative {
            localFile {
              publicURL
            }
          }
        }
      }
      relatedInformation {
        relatedEvent {
          ... on WpHubEvent {
            eventData {
              displayDate
              displayTime
              eventBookingLink
              eventShortDescription
            }
            title
            featuredImage {
              node {
                localFile {
                  publicURL
                  childImageSharp {
                    fluid(maxWidth: 640) {
                      src
                    }
                  }
                }
              }
            }
          }
        }
        relatedArticles {
          ... on WpPost {
            title
            slug
            categories {
              nodes {
                slug
                categoryExtraFields {
                  comingSoon
                }
              }
            }
            featuredImage {
              node {
                localFile {
                  publicURL
                  childImageSharp {
                    fluid(maxWidth: 640) {
                      src
                    }
                  }
                }
              }
            }
          }
        }
        relatedCategory {
          slug
          name
          categoryExtraFields {
            comingSoon
          }
        }
      }
    }
  }
`;

const PostTemplate = ({ data }) => {
  const { post } = data;

  const seoDescription = post.shortDescription;
  const seoImage = post.featuredImage;
  const categoryName = post.categories.nodes[0].name;
  const categorySlug = post.categories.nodes[0].slug;

  const audioAlternative = post.audioAlternative?.longDescriptionAudioAlternative?.localFile?.publicURL;

  const relatedCategories = post.relatedInformation?.relatedCategory;
  const relatedArticles = post.relatedInformation?.relatedArticles;
  const relatedEvent = post.relatedInformation?.relatedEvent;

  return (
    <>
      <SEO title={post.title} description={seoDescription} image={seoImage} />

      <Helmet bodyAttributes={{ class: getCategoryColor(categoryName) }} />

      <Wrapper className={styles.breadcrumb} wrapped>
        <Breadcrumb
          items={[
            {
              to: '/',
              text: 'Home',
            },
            {
              to: `/${categorySlug}`,
              text: categoryName,
            },
            post.title,
          ]}
        />
      </Wrapper>

      <PostHeader
        title={post.title}
        audioAlternative={audioAlternative}
        articleDescription={post.articleDescription}
        audioDiscussion={post.audioDiscussion}
      />

      {/* Quotes and Image */}
      {post.graphicQuotes?.quoteImages?.length > 0 && (
        <Wrapper padded>
          <GraphicQuotesBlock quotes={post.graphicQuotes?.quoteImages} />
        </Wrapper>
      )}

      {/* Video Carousel */}
      {post.videoCarousel?.videos && (
        <Wrapper doublePadded>
          <VideoCarousel videos={post.videoCarousel?.videos} />
        </Wrapper>
      )}

      {/* Video with Audio Image Pairs */}
      {post.videoAudioImagePairs.videoEmbedLink && (
        <>
          <Wrapper padded>
            <VideoAudioImagePairs videoAIPairs={post.videoAudioImagePairs} />
          </Wrapper>
        </>
      )}
      {/* Audio Image Pairs */}
      {post.audioImage?.pairs && (
        <>
          <Wrapper padded>
            <AudioImagePairs pairs={post.audioImage?.pairs} />
          </Wrapper>
        </>
      )}
      {/* Artwork Page Cards */}
      {post.artworkPageCards?.artworkCards && (
        <>
          <Wrapper padded>
            <ArtworkPageCards cards={post.artworkPageCards?.artworkCards} />
          </Wrapper>
        </>
      )}
      {/* Extra Content */}
      {post.extraContent?.extraItems && (
        <Wrapper wrapped padded border>
          <Title tagName="h2">Extra content:</Title>
          <ExtraItems extras={post.extraContent?.extraItems} />
        </Wrapper>
      )}

      {/* Related Content */}
      {/* Categories */}
      {(relatedCategories || relatedArticles || relatedEvent) && (
        <Wrapper padded wrapped border>
          <Title tagName="h2">Enjoyed that? You'll love this</Title>

          <div className={styles.related}>
            {relatedCategories &&
              relatedCategories.map((category, index) => {
                return (
                  <TextSvg
                    tagEl={Link}
                    className={classNames(styles.item, category.slug)}
                    svg={`Circle00${(index % 4) + 1}`}
                    to={category?.categoryExtraFields?.comingSoon ? undefined : `/${category?.slug}`}
                    key={`category-${index}`}
                    image={category.categoryExtraFields?.comingSoon && ComingSoonSvg}
                    containImage
                    colorHover
                  >
                    {category.name}
                  </TextSvg>
                );
              })}
            {relatedArticles &&
              relatedArticles.map((article, index) => {
                const { slug: categorySlug, comingSoon } = getPostCategory(article.categories);

                return (
                  <TextSvg
                    tagEl={comingSoon ? undefined : Link}
                    className={classNames(styles.item, categorySlug)}
                    svg={`Square00${(index % 6) + 1}`}
                    svgClipName={`square00${(index % 6) + 1}Clip`}
                    to={comingSoon ? undefined : `/${article.slug}`}
                    key={`article-${index}`}
                    image={comingSoon ? ComingSoonSvg : undefined}
                    containImage={comingSoon}
                    colorHover
                  >
                    {article.title}
                  </TextSvg>
                );
              })}
            {relatedEvent &&
              relatedEvent.map((event, index) => {
                const p = {
                  date: event.eventData.displayDate,
                  time: event.eventData.displayTime,
                  title: event.title,
                  description: event.eventData.eventShortDescription,
                  link: event.eventData.eventBookingLink,
                  image: getFeaturedImageSrc(event.featuredImage),
                };
                return <EventItem {...p} key={p.link} small />;
              })}
          </div>
        </Wrapper>
      )}
      <Wrapper wrapped padded>
        <TagsSectionFooter />
      </Wrapper>
    </>
  );
};

export default PostTemplate;
