import React from 'react';
import Img from 'gatsby-image';
import { HubAudioPlayer } from '../../../common/HubAudioPlayer';
import { ReactComponent as AudioBox01 } from '../../../../images/svg/audiopairs/001.svg';
import { ReactComponent as AudioBox02 } from '../../../../images/svg/audiopairs/002.svg';
import { ReactComponent as AudioBox03 } from '../../../../images/svg/audiopairs/003.svg';
import { TextSvg } from '../../../common/TextSvg';
import { InternalExternalLink } from '../../../common/InternalExternalLink';
import styles from './ArtworkPageCards.module.scss';

const svgs = [AudioBox01, AudioBox02, AudioBox03];

const ArtworkPageCards = ({ cards }) => {
  return (
    <>
      <div className={styles.artworkGrid}>
        {cards.map((card, index) => (
          <div className={styles.artworkGridInside} key={`artwork-card-${index}`}>
            <TextSvg innerClassName={styles.artwork} svg={svgs[index % svgs.length]} className={styles.inside}>
              <div className={styles.artworkImageWrapper}>
                {card.artworkImage?.localFile?.childImageSharp && (
                  // <InternalExternalLink to={card.artworkLink.url}>
                  <>
                    <Img
                      className={styles.artworkImage}
                      fluid={card.artworkImage?.localFile?.childImageSharp.fluid}
                      alt={card.artworkImage?.altText}
                    />
                    <InternalExternalLink className={styles.imageLink} to={card.artworkLink.url} />
                  </>
                )}
              </div>
              <div className={styles.artworkTitle}>
                <InternalExternalLink to={card.artworkLink.url}>{card.artworkTitle}</InternalExternalLink>
              </div>
              <div>{card.artworkDescription}</div>
              <div className={styles.audioPlayer}>
                <HubAudioPlayer src={card.artworkAudioAlternative?.localFile?.publicURL} label="Listen" />
              </div>
            </TextSvg>
          </div>
        ))}
      </div>
    </>
  );
};

export { ArtworkPageCards };
